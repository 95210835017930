var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.working)?_c('v-card'):_c('v-card',[_c('v-card-title',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('subscriptionList-title'))+" "),_c('v-spacer'),(_vm.allowSubscription)?_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.working,"color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.$t('customerportal-purchaseSubscription'))+" ")])]}}],null,false,1934227844),model:{value:(_vm.purchaseSubscriptionDialog),callback:function ($$v) {_vm.purchaseSubscriptionDialog=$$v},expression:"purchaseSubscriptionDialog"}},[(_vm.purchaseSubscriptionDialog)?_c('purchase-subscription',{attrs:{"relation-id":_vm.relationIdToAddToSubscription},on:{"close":function($event){_vm.purchaseSubscriptionDialog = false},"closesuccess":function($event){return _vm.closePurchaseSubscriptionDialogWithSuccess()}}}):_vm._e()],1):_vm._e()],1),_c('v-card-text'),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.activeSubscriptions,"items-per-page":50,"footer-props":{
                    itemsPerPageOptions: [10,20,50, -1],
                    'items-per-page-text': _vm.$t('entranceList-nrOfItems')
                }},scopedSlots:_vm._u([{key:"item.subscriptionRelations",fn:function(ref){
                var item = ref.item;
return [(item.subscriptionRelations)?_c('div',_vm._l((item.subscriptionRelations),function(obj,index){return _c('span',{key:index},[_vm._v(_vm._s(obj.customerRegistrationNumberRelation ? obj.customerRegistrationNumberRelation.registrationNumber : ''))])}),0):_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('subscriptionList-noVehicles')))])])]}},{key:"item.unitPriceWithoutVatInMinorCurrency",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(item.unitPriceWithoutVatInMinorCurrency))+" ")]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.successDialog),callback:function ($$v) {_vm.successDialog=$$v},expression:"successDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('subscription-purchase-success'))+" ")]),_c('v-card-text',[(_vm.GetSubscriptionMode == 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('subscription-relation-info'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('subscription-relation-info2'))+" "),_c('br'),_c('span',{staticStyle:{"color":"red","font-size":"18px"}},[_vm._v(_vm._s(_vm.$t('subscription-relation-info3')))])]):(_vm.GetSubscriptionMode == 1)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('subscription-company-info'))+" ")]):_vm._e(),_c('br'),_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"100","color":"green"}},[_vm._v(" mdi-check-circle ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"justify-center text-center",attrs:{"loading":_vm.working},on:{"click":function($event){_vm.successDialog = false; _vm.getList()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.paymentErrorDialog),callback:function ($$v) {_vm.paymentErrorDialog=$$v},expression:"paymentErrorDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('payment-error'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('payment-error2'))+" "),_c('br'),_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"100","color":"red"}},[_vm._v(" mdi-close-circle ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"justify-center text-center",attrs:{"loading":_vm.working},on:{"click":function($event){_vm.paymentErrorDialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }